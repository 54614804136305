
import {defineComponent} from "vue"
import Menu from "@/components/Menu.vue"
import MENU_ITEM from "@/interfaces/menu_item"

export default defineComponent({
  name: "Profile",
  components: {Menu},
  data() {
    const menuItems: Array<MENU_ITEM> = []
    return {
      menuItems,
      currentSection: 'account',
    }
  },
  beforeMount() {
    if (!this.$store.getters.userAuthorized) {
      this.$router.replace('/')
      return
    }
    this.menuItems = [
      {title: 'Аккаунт', key: 'account', notAnchor: true},{title: 'Безопасность', key: 'security', notAnchor: true},
      {title: 'Публичный профиль', key: 'public', notAnchor: true}, {title: 'Настройки', key: 'settings', notAnchor: true},
      {title: 'Конфиденциальность', key: 'confidentiality', notAnchor: true}
    ]
  },
  methods: {
    changeSection(event: string) {
      if (event) this.currentSection = event
      // alert(event)
    }
  }
})
